// extracted by mini-css-extract-plugin
export var backgroundAnimation = "user-order-history-module--backgroundAnimation--4bb4f";
export var container = "user-order-history-module--container--7d1ba";
export var grid = "user-order-history-module--grid--a420e";
export var hidden = "user-order-history-module--hidden--5cd03";
export var initialLoading = "user-order-history-module--initial-loading--41871";
export var loader = "user-order-history-module--loader--2919d";
export var loading = "user-order-history-module--loading--51a71";
export var sectionBox = "user-order-history-module--section-box--3374b";
export var tableGrid = "user-order-history-module--table-grid--f03b3";
export var title = "user-order-history-module--title--5abf0";
export var topWrapper = "user-order-history-module--top-wrapper--f1670";