import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

import {
    sectionBox,
    grid,
    initialLoading,
    loading,
    topWrapper,
    container,
    loader,
    tableGrid,
} from './user-order-history.module.scss';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IOrder } from '../../models/order.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useList } from '../../hooks/use-list';
import { getUserTokenData } from '../../utils/get-user-token-data';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useOrder } from '../../hooks/use-order';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';
import useTranslations from '../../hooks/use-translations';
import { useModal } from '../../hooks/use-modal';
import { getRtkQueryErrors } from '../../utils/get-rtk-query-errors';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import NoPermissionInfo from '../organisms/no-permission-info';
import DashboardHeader from '../molecules/dashboard-header';
import AlertInfo from '../molecules/alert-info';

export interface IUserOrderHistoryProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
    type: 'client' | 'candidate';
}

interface IUserOrderHistoryQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
}

const UserOrderHistory: React.FC<IUserOrderHistoryProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
    type,
}) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('UserOrderHistory');
    const tRTK = useTranslations('RTKQueryApi');
    const order = useOrder();
    const token = getUserTokenData();
    const { addModal } = useModal();
    const canShop = useClientPermission(['can-shop']);
    const { allPage } = useStaticQuery<IUserOrderHistoryQueryResult>(query);
    const orderPreviewPage = usePagePathname(
        allPage,
        type === 'client' ? 'client-order-preview' : 'candidate-order-preview'
    );

    const { status, isInitialLoading, items, paginationPaths, isEmpty } = useList<IOrder>({
        endpoint:
            '/orders?expand=orderStatus,items,items.product,items.variant,items.variant,addresses,payments,delivery',
        token: token?.token,
        perPage: 6,
    });

    const handleRetryPayment = async (id: string) => {
        try {
            const retryPayment = await order.retryPayment.fetch(id).unwrap();
            if (retryPayment.redirectUrl && window !== undefined) {
                window.location.href = retryPayment.redirectUrl;
            }
        } catch (rawError) {
            const errors = getRtkQueryErrors(
                rawError as FetchBaseQueryError | SerializedError | undefined,
                tRTK.errors
            );
            addModal({
                modalKey: 'common-modal',
                modalProps: {
                    title: t.retryError.title,
                    confirmText: t.retryError.confirm,
                    children:
                        errors?.map((error) => error.content).join(' ') || t.retryError.content,
                    actions: ['confirm'],
                    type: 'error',
                },
            });
        }
    };

    if (type === 'client' && !canShop) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${status === 'loading' ? loading : ''} ${
                isInitialLoading ? initialLoading : ''
            }`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading ? (
                <Loader className={loader} />
            ) : (
                <div className={container}>
                    <div className={topWrapper}>
                        <DashboardHeader
                            TitleTag={TitleTag}
                            hint={
                                type === 'candidate'
                                    ? t.hintCandidate
                                    : t.hintClient('LINK DO REGULAMINU')
                            }
                        >
                            {t.title}
                        </DashboardHeader>
                    </div>
                    {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                    {!isEmpty && (
                        <Table
                            tableClassName={tableGrid}
                            headerCells={getHeaderElements(t)}
                            rows={getRowElements(
                                t,
                                items as IOrder[],
                                orderPreviewPage,
                                handleRetryPayment
                            )}
                            status={status}
                            paginationPaths={paginationPaths}
                        />
                    )}
                </div>
            )}
        </Section>
    );
};

const getHeaderElements = (t: TLocale['UserOrderHistory']) => {
    return [
        { label: t.id },
        { label: t.date },
        { label: t.product },
        { label: t.value },
        { label: t.status },
        { label: '' },
    ];
};

function getRowElements(
    t: TLocale['UserOrderHistory'],
    orders: IOrder[],
    orderPreviewPage: string,
    handleRetryPayment: (id: string) => void
): ITableProps['rows'] {
    return orders.map((order) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: order.uid,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.date,
                value: `${getDateFromUnixTimestamp(order.createdAt, 'dash')}`,
            },
            {
                type: 'data',
                label: t.product,
                value: order.items[0].variant.name,
            },
            {
                type: 'data',
                label: t.value,
                value: order.finalPrice.grossDisplay,
            },
            {
                type: 'data',
                label: t.status,
                value: order.orderStatus.name,
                valueExtraStyles: {
                    color: order.orderStatus.colorText || '',
                },
            },
            {
                type: 'action',
                actions: [
                    order.payments[0]?.canRetry
                        ? {
                              type: 'button',
                              children: t.retry,
                              stylePreset: 'secondary',
                              size: 'medium',
                              onClick: () => {
                                  if (order.payments[0].canRetry) {
                                      handleRetryPayment(order.payments[0].uuid);
                                  }
                              },
                          }
                        : null,
                    {
                        type: 'button',
                        children: t.details,
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        to: `${orderPreviewPage}?id=${order.uuid}`,
                    },
                ],
            },
        ];
    });
}

export const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-order-preview", "candidate-order-preview"] } }) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default UserOrderHistory;
